
export default {
  name: "Home",
  components: {
    Banner: () => import("@/components/Banner"),
    Award: () => import("@/components/Award"),
  },
  computed: {
    IsMobile() {
      return this.$q.screen.width < 1023;
    },
  },
  data() {
    return {
      banner: null,
      reward: null,
      about: null,
      lang: this.$router.currentRoute.params.lang,
    };
  },
  metaInfo () {
    return {
      title: 'Kaewsamui resort',
      titleTemplate: '%s',
      meta: [
        { name: 'description', content: 'Warm hotel, comfortable like at home. Located in Muang Suratthani Surrounded by the natural atmosphere of Khao Tha Phet' },
        { property: 'og:title', content: 'Kaewsamui resort' },
        { property: 'og:site_name', content: 'Kaewsamui resort' },
        { property: 'og:type', content: 'website' },    
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  mounted() {
    this.getBanner();
    this.getAbout();
  },
  methods: {
    async getBanner() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "banner"),
        this.$prismic.Predicates.at("document.tags", [this.lang, "home"]),
      ]);
      this.banner = results[0].data;
      console.log(this.banner)
    },
    async getAbout() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "about"),
        this.$prismic.Predicates.at("document.tags", [this.lang]),
      ]);
      this.about = results[0].data;
      console.log(this.about);
    },
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
      this.getBanner();
      this.getAbout();
    },
    lang() {
      console.log(this.lang);
    },
  },
};
